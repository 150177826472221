import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import StripesDecoration from "../components/StripesDecoration";

import GustavPoola from "../images/gustav-poola.jpg";
import OskarPoola from "../images/oskar-poola.jpg";
import PriitRaspel from "../images/priit-raspel.jpg";
import TarmoKouhkna from "../images/tarmo-kouhkna.jpg";
import KalleKuuspalu from "../images/kalle-kuuspalu.jpg";
import SergeiZamsharski from "../images/sergei-zamsharski.jpg";
import RaulVahisalu from "../images/raul-vahisalu.jpg";
import MariannKirsipuu from "../images/mariann-kirsipuu.jpg";
import RihoKruuv from "../images/riho-kruuv.jpg";

import GustavPoolaCeo from "../images/gustav-poola-ceo.jpg";
import GustavPoolaSignature from "../images/gustav-poola-signature.png";
import LevercodeTallinn from "../images/levercode-tallinn.png";

import Tehik from "../images/tehik.png";
import Evs from "../images/evs.png";
import Inatba from "../images/inatba.png";
import XroadTiMexico from "../images/xroad-ti-mexico.png";
import TalTech from "../images/taltech.png";
import Iso from "../images/iso.png";
import Cen from "../images/cen.png";
import Itl from "../images/itl.png";

import { Container } from "@mui/material";

const AboutPage = () => (
  <Layout>
    <Seo title="About" />
    {/* Content */}
    <div className="container max-w-screen-xl mt-16 sm:mt-32">
      <h1 className="_h1">About Levercode</h1>
    </div>
    <div className="container max-w-screen-xl flex flex-col md:flex-row sm:gap-8 pt-8 sm:pt-16 sm:text-xl">
      <div className="md:w-7/12">
        <p>Thank you for your interest in our company and Levercode’s applied approach to creating reliable data systems.</p>
        <p className="mt-4 sm:mt-8">
          Data may sound like a dry and complicated domain but nowadays, none of us can ignore it. Out of all the data our main partners process, be them the Governments or large companies, they process data whose ownership is claimed by other persons,
          legal entities, or individuals. Including yours.
        </p>
        <p className="mt-4 sm:mt-8">
          Therefore, our ultimate goal is to make sure that while reaping the benefits of digital technologies, your data are processed and handled efficiently without putting you at risk. Subsequently, we take it as our mission to provide Governments and
          companies with clear and reliable guidance, necessary technologies, and tools in creating reliable and sustainable data governance platforms.
        </p>
        <p className="mt-4 sm:mt-8">Data systems need not to be rocket science. Give us a call or message us! Our experts excel in listening you, explaining difficult domains with easy words and identify how we can offer the best value for data.</p>
      </div>
      <div className="mt-8 sm:mt-8 md:mt-0 mx-auto w-11/12 sm:w-10/12 md:w-5/12">
        <img src={GustavPoolaCeo} alt="Gustav Poola, CEO" />
      </div>
    </div>

    <div className="container max-w-screen-xl pb-16 sm:pb-32 sm:text-xl">
      <p className="mt-8 sm:mt-16">Sincerely,</p>
      <p className="mt-4 sm:mt-8">
        <img className="w-auto h-12 sm:h-16" src={GustavPoolaSignature} alt="" />
      </p>
      <p className="mt-4 sm:mt-8">Gustav Poola, CEO</p>
    </div>

    <div className="bg-blue-800 text-white">
      <StripesDecoration />
      <div className="container max-w-screen-2xl pt-16 sm:pt-32 pb-8 sm:pb-16">
        <h2 className="font-bold text-center text-4xl sm:text-6xl">Our roots and values</h2>
        <p className="text-xl sm:text-3xl leading-normal sm:leading-relaxed mt-8 sm:mt-16 ">
          Levercode was established in 2014 but its roots and <i>raison d'être</i> go much further into history and, through the founders, leaders and involved experts, are closely related to Estonia’s experience in conducting its Digital Transformation.
        </p>

        <div className="grid grid-col-1 lg:grid-cols-2 gap-8 sm:gap-16 mt-8 sm:mt-16 sm:text-2xl">
          <div>
            <p>
              We see Levercode as an “innovative" technology company invoking “old” principles to create reliable, long-term sustainable “new” solutions. Invoking old principles means going back to the drawing board to discover the actual cause of
              associated deficiencies. We focus on complex system’s building blocks and their dependencies to each other rather than just build the “machine” that addresses specific localized issue or problem.
            </p>
          </div>
          <div>
            <p>
              We pay utmost attention to the quality of service and handle all issues that we are faced in our work with scientific approach and methodology. We devote lot of our energy and focus to details without losing the sight of the “big picture” or
              the ultimate objective and qualities of the system we are creating.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container max-w-full bg-blue-800 -pb-full sm:pb-5">
      <p className="mb-1/2 w-11/12 mx-auto text-center sm:mb-0 sm:w-auto">
        <img src={LevercodeTallinn} alt="Tallinn" className="inline-block -mb-1/2 w-full sm:w-auto sm:-mb-80" />
      </p>
    </div>

    <div className="container max-w-screen-xl pt-6 pb-16 sm:pt-96 sm:pb-32 sm:text-2xl">
      <p>
        Since we come from probably <strong>the most innovative e-country in the world</strong>, we are experienced in operating in e-services landscape. Therefore, we have certain expectations on the level of quality of a digital service as well as level
        of data aggregation (data combined from different sources). This forms the ground for specific definitions, explanations and keywords applicable in relevant context. That is natural environment for us but, so far, has been difficult for others to
        comprehend. As a result, we constantly challenge ourselves with a task of tuning ourselves for conveying our unique e-life to other parts of the world.
      </p>
      <p className="mt-8 sm:mt-16">
        Such approach and methodology has turned us to experts in data patterns discovery, processes analysis and modelling and allows us to offer foundational technologies and services built on low risk, manageable cost and long-term reliability for our
        clients to enjoy.
      </p>
    </div>

    <div className="bg-yellow-800 text-gray-800">
      <StripesDecoration className="text-blue-800" />
      <div className="container max-w-screen-2xl text-2xl pt-16 sm:pt-32">
        <h2 className="font-bold text-center text-4xl sm:text-6xl">Our Pool of Experts</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8 sm:mt-24">
          {areasData.map(function (data) {
            return (
              <div key={data.id} className=" bg-white">
                <div className="relative">
                  <div className="absolute inset-x-0 bottom-0 text-white text-center pb-6 sm:pb-8">
                    <h3 className="text-3xl">{data.name}</h3>
                    <p className="text-xl mt-2">{data.role}</p>
                  </div>

                  <img src={data.img} alt={data.name} />
                </div>
                <p className="text-base sm:text-lg p-6 sm:p-8">{data.description}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="container max-w-screen-xl text-gray-800 text-base sm:text-2xl pt-8 sm:pt-16 pb-16 sm:pb-32">
        <p>
          Based on <strong>Partnership Agreement with Tallinn University of Technology (TalTech)</strong>, we also involve <strong>world-renowned Associated Experts</strong> from Institutes of Computer Science, Software Science, and Health Technologies in
          our research lab and projects.
        </p>
      </div>
    </div>

    <div className="bg-gray-100 text-gray-800">
      <Container maxWidth="xl" sx={{pt: 15, pb: 25}}>
        <h2 className="font-bold text-center text-4xl sm:text-6xl text-blue-800">Partners and memberships</h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-16 mt-16 lg:mt-24">
          {partnersData.map(function (data) {
            return (
              <div key={data.id} className="flex items-start sm:items-center gap-4 sm:gap-8">
                <img className="w-3/12 sm:w-48 h-auto" src={data.img} alt={data.title} />
                <div className="w-9/12 sm:w-full">
                  <h3 className="font-bold text-black text-lg leading-normal sm:text-2xl sm:leading-tight mb-2 sm:mb-4">{data.title}</h3>
                  <p className="leading-normal sm:leading-relaxed">
                    {data.description}{" "}
                    <a href={data.url} target="_blank" rel="noreferrer" class="text-blue-800 whitespace-nowrap">
                      Visit website →
                    </a>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  </Layout>
);

const partnersData = [
  {
    id: 0,
    title: "Health and Welfare Information Systems Centre (TEHIK)",
    description: "Under this partnership, Levercode contributes to advancements of data governance practices in Estonian Digital Healthcare ecosystem.",
    img: Tehik,
    url: "https://www.tehik.ee/",
  },
  {
    id: 1,
    title: "Estonian Centre for Standardization and Accreditation",
    description: "Holding the position of Chair, we cooperate with Estonian IT companies in development and implementation of common standards for Blockchain and other DLT.",
    img: Evs,
    url: "https://www.evs.ee/en/",
  },
  {
    id: 2,
    title: "International Association for Trusted Blockchain Applications",
    description: "As member of INATBA, we participate in development of best utilization cases for Blockchain and other DLT solutions.",
    img: Inatba,
    url: "https://inatba.org/",
  },
  {
    id: 3,
    title: "Xroad TI Mexico",
    description: " Under the partnership we provide and develop technologies of e-Governance for Mexico and other countries in the Americas region.",
    img: XroadTiMexico,
    url: "https://www.xroadtimexico.com/",
  },
  {
    id: 4,
    title: "Tallinn University of Technology",
    description: "Based on Partnership Agreement, we involve TalTech Computer Science, Software Science and Health Technologies experts in our Research Lab and projects.",
    img: TalTech,
    url: "https://taltech.ee/en/",
  },
  {
    id: 5,
    title: "International Organization for Standardization",
    description: "We observe the standardization process of Blockchain and other DLT for use in national and European level.",
    img: Iso,
    url: "https://www.iso.org/",
  },
  {
    id: 6,
    title: "European Committee for Standardization",
    description: "We participate in the work of different work formats and groups in developing single EU standards in utilization of Blockchain and other DLT.",
    img: Cen,
    url: "https://www.cen.eu/",
  },
  {
    id: 7,
    title: "Estonian Association of Information Technology and Telecommunications",
    description: "As member of ITL, we actively participate in development of Estonian IT sector and cooperate with other companies in penetration of foreign markets.",
    img: Itl,
    url: "https://itl.ee/en/",
  },
];

const areasData = [
  {
    id: 0,
    name: "Gustav Poola",
    role: "CEO, MSc",
    description:
      "Close to 30 years of diverse experience in the IT technologies, e-platforms, board level integration, large infrastructure development, risk management, and computer science technology markets. He holds international SCRUM Master, Data Protection Officer (DPO), and Money Laundering Risk in Private Banking certificates. Co-author of several patents.",
    img: GustavPoola,
  },
  {
    id: 1,
    name: "Oskar Poola",
    role: "CTO, MSc",
    description:
      "Expert of Analysis of Digital Integrity for Cloud Service Provider platforms. He also excels in Government and commercial e-Governance solutions, technologies, and methods of implementation as well as and digital integrity-oriented application solutions.",
    img: OskarPoola,
  },
  {
    id: 2,
    name: "Priit Raspel",
    role: "Chief Data Architect, MSc",
    description:
      "30 years of experience in IT with the primary focus in data, system architecture and modelling. Coordinated Estonian eID architecture development as Chief Architect for Estonian Information Systems Authority and in capacity of Chief Architect for Ministry of Social Affairs, created data architecture of Estonian e-Health ecosystem and Patient Portal. Expert in commercial banking and insurance sector IT architecture.",
    img: PriitRaspel,
  },

  {
    id: 3,
    name: "Tarmo Kõuhkna",
    role: "Head of Levercode Lab & Research, MBA",
    description:
      "Entrepreneurship professional, Kõuhkna is expert in medical device, mobile communication, renewable energy, and environment industries. Currently, he is engaged in the fields of e-governance, digital identity, and healthcare data systems.",
    img: TarmoKouhkna,
  },
  {
    id: 4,
    name: "Kalle Kuuspalu",
    role: "System Process Architect, MSc",
    description: "Seasoned international technology expert and consultant on integrating IT systems and optimizing workflows by different technologies like Electronic Data Interchange and Process Mining.",
    img: KalleKuuspalu,
  },
  {
    id: 5,
    name: "Sergei Zamsharski",
    role: "Chief Product Officer, B.Sc IT",
    description: "Seasoned product manager with strong technical background on developing complete solutions in telecommunication, logistics, banking and security areas.",
    img: SergeiZamsharski,
  },
  {
    id: 6,
    name: "Raul Vahisalu",
    role: "Head of International Business Development, MBA & MSocSc",
    description:
      "25+ years of experience in the telecom and IT industry, managing development and implementation of high-tech concepts and projects. Expert in value-added services research and development, data acquisition and analysis of B2G platforms as well as design and operation of eID and Interoperability. Author of the patent in the field of mobile parking.",
    img: RaulVahisalu,
  },
  {
    id: 7,
    name: "Mariann Kirsipuu",
    role: "Head of Compliance, MA",
    description:
      "Expert of law, compliance, and risk management in the areas of data and identity management, identity documents, e-Government, and e-services. Experience in drafting and implementation of eIDAS (Regulation (EU) No 910/2014) as well as creation of Estonian e-Residency program.",
    img: MariannKirsipuu,
  },
  {
    id: 8,
    name: "Riho Kruuv",
    role: "Head of Strategic Relations, MA",
    description: "Former diplomat and Estonian Ambassador, Kruuv is expert in global affairs, public policy, Government relations, Digital Transformation, and international business. Avid promoter of e-Governance.",
    img: RihoKruuv,
  },
];

export default AboutPage;
